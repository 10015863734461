const FullScreen: React.FC<{ text: React.ReactNode }> = ({ text, children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
      backgroundColor: '#399eab',
      color: 'white',
      fontSize: '25px'
    }}
  >
    {text}
    {children}
  </div>
)

export default FullScreen
