import FullScreen from 'components/FullScreen'
import { lazy, StrictMode, Suspense } from 'react'
import CacheBuster from 'react-cache-buster'
import ReactDOM from 'react-dom'
import { version } from '../package.json'
import reportWebVitals from './reportWebVitals'

const App = lazy(() => import('./App'))

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<FullScreen text="Loading VissE Cloud..." />}>
      <CacheBuster currentVersion={version} isEnabled={true} loadingComponent={<FullScreen text="Updating VissE Cloud..." />}>
        <App />
      </CacheBuster>
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
